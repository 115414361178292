.AppClock {
    color: #fff;
    text-align: right;
    font-size: 50px;
    cursor: default;
    padding-top: 8px;
}

@media only screen and (max-width: 960px) {
    .AppClock {
        padding-top: 0;
        font-size: 22px;
        text-align: right;
    }
}
