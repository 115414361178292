.AppContainer {
    text-align: center;
    background-color: #123483;
    min-height: 100vh;
}

.App {
    padding-top: 120px;
    width: 80%;
    margin: 0 auto;
    text-align: left;
}

.AppHeader {
    margin-bottom: 70px;
}

.AppHeaderRow, .AppHeaderItem {
    height: 100%;
    text-align: left;
}

.AppHeaderClock {
    text-align: right;
}

.AppLogo {
    height: 100%;
    display: inline-block;
    max-height: 85px;
}

.AppTitle {
    display: inline-block;
    font-size: 55px;
    color: #fff;
    text-align: center;
    margin: 0;
    font-weight: 600;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@media only screen and (max-width: 960px) {
    .AppHeaderItem {
        text-align: left;
    }

    .AppLogo {
        max-height: 30px;
    }

    .AppTitle {
        display: block;
        text-align: left;
        font-size: 30px;
        font-weight: 600;
    }
}

@media only screen and (max-width: 1080px) {
    .App {
        padding-top: 100px;
    }

    .AppHeader {
        margin-top: 60px;
        margin-bottom: 180px;
    }
}
