.LineName {
    color: #fff;
    font-weight: 500;
    padding: 18px 20px;
    font-size: 40px;
}

.LineStatus {
    background: #fff;
    padding: 18px 20px;
    font-weight: 500;
    border-bottom: 1px solid #707070;
    font-size: 40px;
}

@media only screen and (min-width: 1080px) {
    .LineStatus, .LineName {
        font-size: 35px;
        padding: 15px 20px;
    }
}
